<template>
  <div>
    <!-- Filters -->
    <withdrawals-list-filters
      :reference-filter.sync="referenceFilter"
      :type-filter.sync="typeFilter"
      :currency-filter.sync="currencyFilter"
      :status-filter.sync="statusFilter"
      :username-filter.sync="usernameFilter"
      :bank-account-code-filter.sync="bankAccountCodeFilter"
      :bank-account-number-filter.sync="bankAccountNumberFilter"
      :bank-filter.sync="bankFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :tags-filter.sync="tagsFilter"
      :type-options="typeOptions"
      :currency-options="currencyOptions"
      :status-options="statusOptions"
      :bank-options.sync="bankOptions"
      :tags-options="tagsOptions"
      @refetch-data="refetchData"
    />
    <!-- :fromdate-filter.sync="fromdateFilter"
    :todate-filter.sync="todateFilter"
    :refresh-top-deposit-analytic.sync="refreshTopDepositAnalytic" -->
    <analytic-withdrawal
    />
    <b-card no-body class="mb-0">
      <div class="p-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- <b-col
						lg="9"
						cols="12"
						class="align-items-center d-flex flex-wrap mb-sm-1"
					>
						Pending:
						<span
							class="text-primary mx-50 cursor-pointer"
						> 0 </span>
						| Review: <span class="mx-1"> 0 </span>
						| Escalated: <span class="mx-1"> 0 </span>
						| Submit: <span class="mx-1"> 0 </span>
						| Auto Proccessing: <span class="mx-1"> 0 </span>
					</b-col> -->

          <!-- Search -->
          <b-col
            v-if="$can('export', 'withdrawal')"
            class="col d-flex justify-content-end"
          >
            <b-button
              v-if="!showLoadingExport"
              variant="primary"
              @click="exportData"
            >
              <feather-icon icon="DownloadIcon" size="16" /> {{ $t("Export") }}
            </b-button>
            <b-button v-else variant="primary" disabled>
              <b-spinner small />
              {{ $t("Exporting") }}
            </b-button>
            <div class="hidden">
              <vue-export-excel
                ref="exportButton"
                :data="data"
                :columns="columns"
                :file-name="exportFiletitle"
                :file-type="'xlsx'"
                :sheet-name="exportFileSheetName"
              >
                {{ $t("Export") }}
              </vue-export-excel>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refWithdrawalListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchWithdrawals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(ref)="data">
          <b-link @click="showWithdrawalDetail(data.item)">
            {{ data.item.ref }}
            <feather-icon
              v-if="data.item.bills.length > 0"
              icon="PaperclipIcon"
            />
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          <div v-if="data.item.user">
            <b-link
              :to="{
                name: 'apps-users-view',
                params: { id: data.item.user_id },
              }"
            >
              {{ data.item.user.username }}
            </b-link>
          </div>
        </template>

        <!-- Column: mã đại lý -->
        <template #cell(affiliate_id)="data">
          <b-link
              v-if="data.item.user"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.affiliate_id }}
          </b-link>
        </template>

        <template #cell(tags)="data">
          <b-badge
            v-if="
              data.item.user &&
              data.item.user.user_tags.length > 0 &&
              data.item.user.user_tags.name
            "
            :style="`background-color: ${data.item.user.user_tags[0].tag.color}`"
          >
            <feather-icon icon="TagIcon" class="mr-25" />
            {{ ...data.item.user.user_tags.map(tag => tag.tag.name) }}
          </b-badge>
        </template>

        <template #cell(type)="data">
          {{ resolvePaymentType(data.item.type) }}
        </template>

        <template #cell(fullName)="data">
          <div v-if="data.item.user">
            {{ data.item.user.name }}
          </div>
        </template>

        <template #cell(member_bank_name)="data">
          <div v-if="data.item.user_bank_account">
            {{ data.item.user_bank_account.bank_name }}
          </div>
        </template>

        <template #cell(member_bank_account_name)="data">
          {{ data.item.user_bank_account ? data.item.user.name : "" }}
        </template>

        <template #cell(member_bank_account_number)="data">
          {{
            data.item.user_bank_account
              ? data.item.user_bank_account.bank_account
              : ""
          }}
        </template>

        <template #cell(amount)="data">
          <span class="text-primary">{{
            numberFormat(data.item.amount / 1000)
          }}</span>
        </template>
        <template #cell(net_amount)="data">
          <span class="text-primary">{{
            numberFormat(data.item.net_amount / 1000)
          }}</span>
        </template>
        <template #cell(fee)="data">
          <span class="text-primary">{{
            numberFormat(data.item.fee / 1000)
          }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div
            :class="`text-capitalize align-items-center d-flex`"
            v-if="data.item.txn_status"
          >
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />
            {{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <template #cell(remark)="data">
          {{ data.item.remark }}
        </template>

        <template #cell(messageRefund)="data">
          {{ data.item.message_refund }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-if="
              data.item.stat == statusProccess &&
              $can('update', 'withdrawalstatus')
            "
            variant="success"
            class="btn-icon btn-sm mr-50"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-success
            :title="$t('Complete')"
            @click="approveWithdrawalModal(data.item, 4)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-if="
              data.item.stat == statusProccess &&
              $can('update', 'withdrawalstatus')
            "
            variant="primary"
            class="btn-icon btn-sm mr-50"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-info
            title="Approve"
            @click="approveWithdrawalModal(data.item, 7)"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="
              data.item.stat == statusProccess &&
              $can('update', 'withdrawalstatus')
            "
            variant="danger"
            class="btn-icon btn-sm mr-50"
            @click="approveWithdrawalModal(data.item, 6)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            v-else
            disabled
            variant="outline-secondary"
            class="btn-icon btn-sm mr-50"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Upload')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon btn-sm mr-50"
            @click="showUploadBill(data.item.ref)"
          >
            <feather-icon icon="UploadIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Detail')"
            variant="outline-secondary"
            class="btn-icon btn-sm"
            @click="showWithdrawalDetail(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalWithdrawals"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Approve Withdrawal Modal -->
    <approve-withdrawal-modal
      :withdrawal-data.sync="withdrawalData"
      :type.sync="type"
      @refetch-data="refetchData"
    />
    <!-- Detail Modal -->
    <withdrawal-detail-modal :withdrawal-data.sync="withdrawalData" />

    <!-- Upload Bill Modal -->
    <upload-bill-modal :reference.sync="reference" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BFormTextarea,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText, numberFormat, formatDateTime } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import WithdrawalsListFilters from "./WithdrawalsListFilters.vue";
import useWithdrawalsList from "./useWithdrawalsList";
import withdrawalStoreModule from "./withdrawalStoreModule";
import bankStoreModule from "@/views/local-setting/local/bankStoreModule";
import currencyStoreModule from "@/views/local-setting/local/currencyStoreModule";
import tagStoreModule from "@/views/apps/user/tag/tagStoreModule";
import VueExportExcel from "@/views/extensions/export/VueExportExcel.vue";
import AnalyticWithdrawal from "@/views/payments/analytic/AnalyticWithdrawal.vue";

export default {
  components: {
    WithdrawalsListFilters,
    ApproveWithdrawalModal: () => import("./ApproveWithdrawalModal.vue"),
    WithdrawalDetailModal: () => import("./WithdrawalDetailModal.vue"),
    UploadBillModal: () =>
      import("@/views/payments/upload-bill/UploadBillModal.vue"),
    VueExportExcel,
    AnalyticWithdrawal,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    BSpinner,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      withdrawalData: null,
      type: null,
      statusProccess: 2, // 1: pending, 7: approved, 6: rejected, 2: success, 5: request
      showLoadingExport: false,
      columns: [
        { label: "#", field: "id" },
        { label: "Transaction ID", field: "ref" },
        { label: "Username", field: "user", dataFormat: this.showUsername },
        // { label: 'Tags', field: 'tags.name' },
        { label: "Type", field: "type", dataFormat: this.resolvePaymentType },
        { label: "Full Name", field: "user", dataFormat: this.showName },
        { label: "Member Bank Name", field: "bank_name" },
        { label: "Member Bank Account Name", field: "account_name" },
        { label: "Member Bank Account Number", field: "account_number" },
        { label: "Currency", field: "currency" },
        {
          label: "Amount",
          field: "amount",
          dataFormat: (value) => value / 1000,
        },
        {
          label: "Net Amount",
          field: "net_amount",
          dataFormat: (value) => value / 1000,
        },
        { label: "Fee", field: "fee", dataFormat: (value) => value / 1000 },
        {
          label: "Status",
          field: "stat",
          dataFormat: (value) => this.resolveStatus(value).label,
        },
        {
          label: "Created At",
          field: "created_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
        {
          label: "Updated At",
          field: "updated_at",
          dataFormat: (value) => formatDateTime(value).replace(".000000Z", ""),
        },
      ],
      data: [],
      exportFiletitle: `Withdrawals-${new Date()
        .toJSON()
        .replace(":", "-")
        .substring(0, 16)}`,
      exportFileSheetName: "Withdrawals Transactions",
      reference: null,
    };
  },
  methods: {
    approveWithdrawalModal(withdrawalData, type) {
      this.withdrawalData = withdrawalData;
      this.type = type;
      this.$bvModal.show("modal-approve-withdrawal");
    },
    showWithdrawalDetail(withdrawalData) {
      this.withdrawalData = withdrawalData;
      this.$bvModal.show("modal-withdrawal-detail");
    },
    showUploadBill(transactionId) {
      this.reference = transactionId;
      this.$bvModal.show("modal-upload-bill");
    },
    showUsername(value) {
      return value ? value.username : "";
    },
    showName(value) {
      return value ? value.name : "";
    },
    async getExportData() {
      await store
        .dispatch("payment-withdrawal/exportWithdrawals", {
          type: this.typeFilter,
          username: this.usernameFilter,
          reference: this.referenceFilter,
          fromdate: this.fromdateFilter,
          todate: this.todateFilter,
          bankId: this.bankFilter,
          stat: this.statusFilter,
          currency: this.currencyFilter,
          bank_account_code: this.bankAccountCodeFilter,
          bank_account_number: this.bankAccountNumberFilter,
          tags: this.tagsFilter,
        })
        .then((response) => {
          if (response.data.code == "00") {
            this.data = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    async exportData() {
      this.showLoadingExport = true;
      await this.getExportData();
      await this.delay(2000);
      this.showLoadingExport = false;
      this.$refs.exportButton.$el.click();
    },
    delay(ms) {
      return new Promise((resolve, reject) => setTimeout(resolve, ms));
    },
  },
  setup() {
    if (!store.hasModule("local-setting-currency"))
      store.registerModule("local-setting-currency", currencyStoreModule);
    if (!store.hasModule("payment-withdrawal"))
      store.registerModule("payment-withdrawal", withdrawalStoreModule);
    if (!store.hasModule("local-setting-bank"))
      store.registerModule("local-setting-bank", bankStoreModule);
    if (!store.hasModule("app-user-tag"))
      store.registerModule("app-user-tag", tagStoreModule);
    onUnmounted(() => {
      if (store.hasModule("payment-withdrawal"))
        store.unregisterModule("payment-withdrawal");
      if (store.hasModule("local-setting-bank"))
        store.unregisterModule("local-setting-bank");
    });

    const statusOptions = ref([]);
    const bankOptions = ref([]);
    const currencyOptions = ref([]);
    const tagsOptions = ref([]);
    store
        .dispatch("local-setting-currency/fetchOptionStatus")
        .then((response) => {
          statusOptions.value = response.data.data.map((val) => {
            return {
              label: val.name,
              value: val.id,
            };
          });
        });

    store.dispatch("app-user-tag/fetchTagTypesFilter", {}).then((response) => {
      const { tagTypes } = response;
      tagsOptions.value = tagTypes;
    });

    store.dispatch("local-setting-bank/fetchBanks", {}).then((response) => {
      bankOptions.value = response;
    });

    store.dispatch("local-setting-currency/fetchCurrencies").then((response) => {
      if (response.data.code == "00") {
        currencyOptions.value = response.data.data;
      }
    });

    const {
      fetchWithdrawals,
      tableColumns,
      perPage,
      currentPage,
      totalWithdrawals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWithdrawalListTable,
      refetchData,

      // UI
      resolveStatus,
      resolvePaymentType,

      referenceFilter,
      typeFilter,
      currencyFilter,
      statusFilter,
      usernameFilter,
      bankAccountCodeFilter,
      bankAccountNumberFilter,
      bankFilter,
      fromdateFilter,
      todateFilter,
      tagsFilter,
      typeOptions,
    } = useWithdrawalsList();

    return {
      fetchWithdrawals,
      tableColumns,
      perPage,
      currentPage,
      totalWithdrawals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWithdrawalListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      currencyOptions,

      // UI
      resolveStatus,
      resolvePaymentType,

      statusOptions,
      typeOptions,
      tagsOptions,
      bankOptions,

      // rejectReason,

      referenceFilter,
      typeFilter,
      currencyFilter,
      statusFilter,
      usernameFilter,
      bankAccountCodeFilter,
      bankAccountNumberFilter,
      bankFilter,
      fromdateFilter,
      todateFilter,
      tagsFilter,
    };
  },
};
</script>
